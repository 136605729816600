<template>
  <descriptive-content
    :category="category"
    :layout="layout"
    :is-digital-signage="isDigitalSignage"
    :breadcrumb="breadcrumb">
    <div
      id="player"
      class="video">
    </div>
  </descriptive-content>
</template>

<script>
  import OvenPlayer from 'ovenplayer'

  export default {
    props: [
      'layout',
      'category',
      'isDigitalSignage',
    ],
    computed: {
      createSources() {
        let streamType = this.$helper.findAttributeValue(this.category, 'CMS-StreamType')
        switch (`${streamType}`.toLowerCase()) {
          case 'webrtc':
            return [{
              label: 'WebRTC',
              type: 'webrtc',
              file: this.category.streamurlWs
            }]
          case 'hls':
          default:
            return [
              { label: 'HLS', type: 'hls', file: this.category.streamurl },
              { label: 'HLS 360', type: 'hls', file: this.category.streamurl360 },
              { label: 'HLS 480', type: 'hls', file: this.category.streamurl480 },
              { label: 'HLS 720', type: 'hls', file: this.category.streamurl720 },
            ]
        }
      }
    },
    data() {
      return {
        player: null,
      }
    },
    mounted() {
      if (this.category) {
        this.player = OvenPlayer.create('player', {
          sources: this.createSources
        })
      }
    },
    inject: [
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
  .video {
    @apply
      w-full
      h-full
      max-w-full
      max-h-full;
  }
</style>